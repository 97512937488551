[_nghost-sc262] {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app-layout[_ngcontent-sc262] {
    height: 100vh;
}

.menu-sidebar[_ngcontent-sc262] {
    position: relative;
    z-index: 10;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    box-shadow: none;
    min-height: calc(100vh - 57px);
    margin: -4px 0;
}

.header-trigger[_ngcontent-sc262] {
    height: 64px;
    padding: 20px 24px;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s, padding 0s;
}

.sidebar-logo[_ngcontent-sc262] {
    position: relative;
    height: 64px;
    overflow: hidden;
    line-height: 64px;
    background: #001529;
    transition: all 0.3s;
}

.sidebar-logo[_ngcontent-sc262] img[_ngcontent-sc262] {
    height: 32px;
    width: 32px;
}

.sidebar-logo[_ngcontent-sc262] h1[_ngcontent-sc262],
.sidebar-logo[_ngcontent-sc262] img[_ngcontent-sc262] {
    display: inline-block;
    vertical-align: middle;
}

.sidebar-logo[_ngcontent-sc262] h1[_ngcontent-sc262] {
    margin: 0 0 0 20px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    font-family: Open Sans, sans-serif;
}

nz-header[_ngcontent-sc262] {
    padding: 0 24px;
    width: 100%;
    z-index: 2;
}

.app-header[_ngcontent-sc262] {
    position: relative;
    height: 64px;
    padding: 0;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

nz-content[_ngcontent-sc262] {
    margin: 24px;
}

.inner-content[_ngcontent-sc262] {
    padding: 24px;
    background: #fff;
    height: 100%;
}

.ant-layout-content[_ngcontent-sc262] {
    max-height: calc(100vh - 110px);
}

i[_ngcontent-sc262] {
    margin-right: 0 !important;
}

.ant-menu-item[_ngcontent-sc262] span[_ngcontent-sc262] {
    margin-left: 10px !important;
}

.sidebar-logo[_ngcontent-sc262] {
    background: #fff;
}

[_ngcontent-sc262] img[_ngcontent-sc262] {
    height: 35px;
    width: auto;
}

.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list,
.slick-slider {
    position: relative;
    display: block;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    transform: translateZ(0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: "";
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir="rtl"] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}

* {
    box-sizing: border-box;
    margin: 0;
}

::-moz-selection {
    background: transparent;
    color: #f27107;
}

::selection {
    background: transparent;
    color: #f27107;
}

body {
    font-variant: none !important;
    -ms-font-feature-settings: "lnum" 1;
    -o-font-feature-settings: "lnum" 1;
    font-feature-settings: "lnum" 1;
    color: #b3b3b3;
    font-family: Archivo Regular, sans-serif;
    font-weight: 400;
    font-size: 18px;
    background-color: #191919;
}

a:hover {
    color: inherit;
}

button {
    border: none;
    cursor: pointer;
}

button:focus {
    outline: none;
}

/* a,
h1,
h2,
h3,
h4 {
    color: #b3b3b3;
} */

p {
    margin: 0;
}

section {
    padding: 0 10vw;
    max-width: 100vw;
}

.col--100 {
    width: 100%;
}

.col--50 {
    width: 50%;
}

.col--45 {
    width: 45%;
}

.col--30 {
    width: 30%;
}

.col--20 {
    width: 20%;
}

.mb--50 {
    margin-bottom: 50px;
}

.mt-20 {
    margin-top: 20px;
}

.absolute {
    position: absolute;
}

.slick-slide,
.slick-slide[aria-hidden="true"]:not(.slick-cloned)~.slick-cloned[aria-hidden="true"] {
    transition: opacity 0.3s;
}

.slick-center,
.slick-slide[aria-hidden="true"]:not([tabindex="-1"])+.slick-cloned[aria-hidden="true"] {
    opacity: 1;
}

.white {
    color: #e6e6e6;
}

.bg--white {
    background-color: #e6e6e6;
}

.light-grey {
    color: #b3b3b3;
}

.grey {
    color: grey;
}

.orange {
    color: #f27107;
}

.bg--orange {
    background-color: #f27107;
}

.black {
    color: #131313;
}

.uppercase {
    text-transform: uppercase;
}

.archivo-e_bold {
    font-family: Archivo Expanded Bold;
    font-weight: 700;
}

.archivo {
    font-family: Archivo Regular;
    font-weight: 400;
}

.archivo--black {
    font-family: Archivo Black;
    font-weight: 900;
}

.archivo--eb {
    font-family: Archivo ExtraBold;
    font-weight: 800;
}

b {
    font-family: Archivo Bold;
    font-weight: 700;
}

.section__title {
    font-size: 18px;
    position: relative;
    line-height: 1.3;
    color: #e6e6e6;
    font-family: Archivo Regular;
    font-weight: 400;
}

.f--14 {
    font-size: 14px;
}

.f--14,
.f--16 {
    line-height: 1.2em;
}

.f--16 {
    font-size: 16px;
}

.f--18 {
    font-size: 18px;
    line-height: 1.5em;
}

.f--24 {
    font-size: 24px;
    line-height: 1.3em;
}

.f--32 {
    font-size: 32px;
    line-height: 1em;
}

.f--36 {
    font-size: 3vw;
}

.f--36,
.f--40 {
    line-height: 1.1em;
}

.f--40 {
    font-size: 3.3vw;
}

.display--inline {
    display: block;
}

.section--flex {
    display: flex;
    flex-wrap: wrap;
}

.flex--jc-ac {
    justify-content: center;
}

.flex--jc-ac,
.flex--js-ac {
    display: flex;
    align-items: center;
}

.flex--js-ac {
    justify-content: flex-start;
}

.flex--je-ae {
    justify-content: flex-end;
}

.flex--je-ae,
.flex--js-ae {
    display: flex;
    align-items: flex-end;
}

.flex--js-ae {
    justify-content: flex-start;
}

.flex--js-as {
    justify-content: flex-start;
}

.flex--js-as,
.flex--jsb-as {
    display: flex;
    align-items: flex-start;
}

.flex--jsb-ac,
.flex--jsb-as {
    justify-content: space-between;
}

.flex--jsb-ac {
    display: flex;
    align-items: center;
}

.mobile--only {
    display: none;
}

.desktop--only {
    display: block;
}

.text--vertical {
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}

hr {
    flex-basis: 100%;
    height: 0;
    margin: 0;
    border: 0;
}

.header__select.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border: none;
}

.header__select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
}

.header__select.ant-select,
.header__select .anticon,
.homepage .header__select.ant-select,
.homepage .header__select .anticon {
    color: #e6e6e6;
}

.header__select.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
}

.products .slick-list {
    overflow: inherit !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
}

.ant-checkbox-inner {
    border-radius: 0;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-inner {
    background-color: transparent;
    border: 2px solid #e6e6e6;
}

.ant-checkbox-checked:after {
    border: none !important;
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
    border-color: #e6e6e6;
}

.slick-slide:focus {
    outline: none;
}

.ant-input,
.ant-select {
    border-color: #e6e6e6 !important;
}

.ant-input-focused,
.ant-input:focus {
    box-shadow: none;
    border-right-width: 3px !important;
    border-color: #e6e6e6;
}

.ant-select-dropdown {
    border-radius: 2px !important;
    padding: 0 !important;
    box-shadow: 1px 3px 12px hsla(0, 0%, 39.2%, 0.1) !important;
}

.ant-select-item-option,
.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #e6e6e6;
    color: #131313;
}

.ant-select-item:hover {
    background-color: #b3b3b3;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    transition: none !important;
}

.cookie-policy .iub_content h2,
.privacy-policy .iub_content h1 {
    font-family: Archivo Expanded Bold;
    color: #e6e6e6;
    line-height: 1.1;
}

.cookie-policy .iub_content h3,
.privacy-policy .iub_content h2 {
    font-family: Archivo Expanded Bold;
    font-size: 25px;
    color: #e6e6e6;
    margin-top: 50px;
}

.cookie-policy .iub_content h4,
.privacy-policy .iub_content h3 {
    font-size: 20px;
    font-family: Archivo Expanded Bold;
    margin-top: 25px;
    margin-bottom: 0;
}

.iub_content a {
    color: #e6e6e6;
}

.iub_content dl,
.iub_content ol,
.iub_content ul {
    list-style-type: circle;
}

a.iubenda-tp-btn.iubenda-cs-preferences-link {
    display: none !important;
    visibility: hidden;
}

button {
    background-color: none !important;
}

.browser--ie {
    position: fixed;
    height: 100%;
    width: 100vw;
    background-color: #131313;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
    opacity: 0;
    visibility: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.browser--ie.ie {
    display: flex;
    opacity: 1;
    visibility: visible;
}

.browser--ie .logo--ie {
    width: 150px;
    height: 150px;
}

.browser--ie p {
    margin-top: 20px;
    width: 250px;
    text-align: center;
}

.mbm {
    margin-bottom: inherit;
}

@media screen and (max-width: 1024px) {
    section {
        padding-left: 30px;
        padding-right: 30px;
        width: 90vw;
    }

    .section--flex {
        flex-direction: column;
    }

    .col--20,
    .col--30,
    .col--45,
    .col--50 {
        width: 100%;
    }

    .f--14 {
        font-size: 14px;
    }

    .f--14,
    .f--16 {
        line-height: 1.2em;
    }

    .f--16 {
        font-size: 15px;
    }

    .f--18 {
        font-size: 16px;
        line-height: 1.5em;
    }

    .f--24 {
        font-size: 21px;
        line-height: 1.3em;
    }

    .f--32 {
        font-size: 28px;
        line-height: 1em;
    }

    .f--36 {
        font-size: 36px;
    }

    .f--36,
    .f--40 {
        line-height: 1.1em;
    }

    .f--40 {
        font-size: 40px;
    }

    .container__inner {
        width: 90vw !important;
    }

    .mobile--only {
        display: block;
    }

    .desktop--only {
        display: none !important;
    }
}

@media screen and (max-width: 1024px) {
    .mbm {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 768px) {

    .f--14,
    .f--18 {
        font-size: 14px;
    }

    .f--14,
    .f--16 {
        line-height: 1.2em;
    }

    .f--16 {
        font-size: 15px;
    }

    .f--18 {
        font-size: 16px;
        line-height: 1.5em;
    }

    .f--24 {
        font-size: 18px;
        line-height: 1.3em;
    }

    .f--32 {
        font-size: 28px;
        line-height: 1em;
    }

    .f--36 {
        font-size: 23px;
    }

    .f--36,
    .f--40 {
        line-height: 1.1em;
    }

    .f--40 {
        font-size: 32px;
    }
}

.stripe--1[_ngcontent-sc306] {
    display: grid;
    grid-template-columns: calc(5vw + 2px);
    grid-template-rows: 40vh 20vh 20vh 20vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10003;
    cursor: pointer;
}

.stripe--1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:first-child {
    padding-top: 35px;
}

.stripe__item[_ngcontent-sc306] {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    background-color: transparent;
}

.stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
    -ms-writing-mode: tb-lr;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-size: 18px;
}

.arrow--menu[_ngcontent-sc306] {
    fill: none;
    stroke: #b3b3b3;
    stroke-miterlimit: 10;
}

.hamburger[_ngcontent-sc306] {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    width: 100%;
}

.hamburger[_ngcontent-sc306] svg[_ngcontent-sc306] {
    width: 75%;
    max-width: 40px;
}

.menu-item[_ngcontent-sc306] {
    margin-right: 5px;
}

.stripe--1__content[_ngcontent-sc306] {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.stripe--2[_ngcontent-sc306] {
    right: -75vw;
    width: 80vw;
    z-index: 10002;
    padding-left: 5vw;
}

.close__layer[_ngcontent-sc306],
.stripe--2[_ngcontent-sc306] {
    position: fixed;
    top: 0;
    height: 100vh;
}

.close__layer[_ngcontent-sc306] {
    z-index: 104;
    left: 0;
    width: 100vw;
    background-color: rgba(25, 25, 25, 0.8);
    cursor: pointer;
    display: none;
    opacity: 0;
}

.stripe--status[_ngcontent-sc306] {
    width: 100%;
    height: 100%;
    padding: 60px 5vw 80px;
    position: relative;
    overflow-y: auto;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306],
.stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306],
.stripe--2[_ngcontent-sc306] .stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] {
    position: relative;
    transition: all 0.2s ease-in-out;
    display: block;
    line-height: 1.8;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] svg[_ngcontent-sc306],
.stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] svg[_ngcontent-sc306],
.stripe--2[_ngcontent-sc306] .stripe--status3[_ngcontent-sc306] svg[_ngcontent-sc306] {
    width: 40px;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306],
.stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306],
.stripe--2[_ngcontent-sc306] .stripe--status3[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306] {
    width: 60px;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306]:hover span[_ngcontent-sc306],
.stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306]:hover span[_ngcontent-sc306],
.stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306]:hover span[_ngcontent-sc306] {
    color: grey;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] span[_ngcontent-sc306],
.stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306] span[_ngcontent-sc306],
.stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] span[_ngcontent-sc306] {
    transition: color 0.2s ease;
}

.stripe--status1[_ngcontent-sc306],
.stripe--status2[_ngcontent-sc306],
.stripe--status3[_ngcontent-sc306] {
    background-color: #ccc;
}

.stripe--status1[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] {
    display: flex;
    align-items: center;
    height: 100%;
}

.stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] {
    margin-bottom: 20px;
}

.stripe--2[_ngcontent-sc306] .products__container[_ngcontent-sc306] {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.stripe--2[_ngcontent-sc306] .products__content[_ngcontent-sc306] {
    -moz-columns: 2;
    column-count: 2;
    width: 100%;
}

.stripe--status3[_ngcontent-sc306] .project-image[_ngcontent-sc306] {
    position: absolute;
    z-index: 0;
    right: -5vw;
    top: 25%;
    height: 50vh;
    opacity: 0.3;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] a[_ngcontent-sc306] {
    font-size: 16px;
    margin-top: 15px;
    position: relative;
}

.content--bottom--anim[_ngcontent-sc306] {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] .content--bottom--anim[_ngcontent-sc306] a[_ngcontent-sc306]:after {
    content: "";
    position: absolute;
    bottom: 5px;
    height: 2px;
    width: 0;
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
}

.stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] .content--bottom--anim[_ngcontent-sc306] a[_ngcontent-sc306]:hover:after {
    width: 20px;
}

.stripe--2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] {
    padding-top: 50px;
    padding-bottom: 70px;
}

.stripe--2[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] {
    width: 30vw;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.stripe--2[_ngcontent-sc306] .content--top[_ngcontent-sc306] {
    width: 100%;
}

.stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306],
.stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] {
    display: block;
}

.flex[_ngcontent-sc306] {
    min-height: calc(100vh - 250px);
    position: relative;
    display: flex;
    align-items: center;
}

.stripe--status3[_ngcontent-sc306] .project-list__container[_ngcontent-sc306] {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] img[_ngcontent-sc306] {
    width: 20vw;
    position: absolute;
    top: 0;
    left: 0;
}

.stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306] {
    position: relative;
    z-index: 13;
}

.menu-line[_ngcontent-sc306] {
    fill: none;
    stroke: #999;
    stroke-miterlimit: 10;
    stroke-width: 2.83px;
}

.menu-line2[_ngcontent-sc306] {
    fill: none;
    stroke: #f27107;
    stroke-miterlimit: 10;
    stroke-width: 2.83px;
    stroke-dashoffset: 68.62;
    stroke-dasharray: 0, 999999px;
}

svg.arrow--status1[_ngcontent-sc306] {
    right: 10vw;
    bottom: 15px;
    position: absolute;
}

.stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306] {
    right: 10%;
}

.stripe--status3[_ngcontent-sc306] svg.arrow--status1[_ngcontent-sc306] {
    right: -10%;
}

@media screen and (max-width: 1024px) {
    .stripe--1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:first-child {
        background-color: #f27107;
        padding-top: 35px;
    }

    .hamburger[_ngcontent-sc306] span[_ngcontent-sc306] {
        width: 7vw;
    }

    .stripe--2[_ngcontent-sc306] {
        width: 100vw;
    }

    .stripe--1[_ngcontent-sc306] {
        grid-template-columns: calc(10vw + 2px);
    }

    .stripe--2[_ngcontent-sc306] {
        right: -90vw;
        padding-left: 10vw;
    }

    .stripe--status[_ngcontent-sc306] {
        padding: 60px 5vw 80px 10vw;
    }

    .stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] {
        font-size: 50px;
    }

    .stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] a[_ngcontent-sc306] {
        font-size: 16px;
        line-height: 1.7;
    }

    .stripe--2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] {
        padding-top: 10px;
    }

    .stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] div[_ngcontent-sc306] {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306],
    .stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] {
        font-size: 25px;
    }

    .stripe--2[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] {
        width: 70vw;
    }

    .stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
        font-size: 16px;
    }

    .arrow--menu[_ngcontent-sc306] {
        fill: none;
        stroke: #e6e6e6;
        stroke-miterlimit: 10;
    }

    .stripe--status3[_ngcontent-sc306] .project-image[_ngcontent-sc306] {
        right: 0;
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
    .stripe--1[_ngcontent-sc306] {
        grid-template-rows: 30vh 35vh 35vh 20vh;
    }

    .stripe--2[_ngcontent-sc306] {
        padding-left: 59px;
    }

    .stripe--2[_ngcontent-sc306] .content--top[_ngcontent-sc306] {
        -moz-columns: 2;
        column-count: 2;
    }

    .content--top[_ngcontent-sc306] svg[_ngcontent-sc306],
    .products__content[_ngcontent-sc306] svg[_ngcontent-sc306],
    .project-list__container[_ngcontent-sc306] svg[_ngcontent-sc306],
    .stripe--status3[_ngcontent-sc306] .project-image[_ngcontent-sc306] {
        display: none;
    }

    .stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] {
        font-size: 24px;
    }

    .stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
        font-size: 12px;
    }

    .stripe--status[_ngcontent-sc306] {
        padding: 30px 5vw 0;
        overflow: scroll;
    }

    .stripe--1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:first-child {
        padding-top: 15px;
    }

    .stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] div[_ngcontent-sc306] {
        padding-top: 10px;
        padding-bottom: 30px;
    }

    .stripe--2[_ngcontent-sc306] .products__container[_ngcontent-sc306] {
        height: auto;
    }

    .stripe--status3[_ngcontent-sc306] .project-list__container[_ngcontent-sc306] {
        position: relative;
        z-index: 1;
        top: auto;
        transform: none;
    }
}

@media screen and (max-width: 500px) {
    span.menu-item.f--40[_ngcontent-sc306] {
        font-size: 100%;
    }

    .stripe--1[_ngcontent-sc306] {
        grid-template-rows: 25vh 30vh 30vh 15vh;
    }

    .content--top[_ngcontent-sc306] svg[_ngcontent-sc306],
    .products__content[_ngcontent-sc306] svg[_ngcontent-sc306],
    .project-list__container[_ngcontent-sc306] svg[_ngcontent-sc306],
    .stripe--status3[_ngcontent-sc306] .project-image[_ngcontent-sc306] {
        display: none;
    }

    .stripe--2[_ngcontent-sc306] .stripe--status2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] div[_ngcontent-sc306],
    .stripe--2[_ngcontent-sc306] .stripe--status3[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] div[_ngcontent-sc306] {
        -moz-columns: 1;
        column-count: 1;
    }

    .stripe--2[_ngcontent-sc306] .content__wrap[_ngcontent-sc306] {
        padding-bottom: 10px;
    }

    .stripe--status2[_ngcontent-sc306] a[_ngcontent-sc306],
    .stripe--status3[_ngcontent-sc306] a[_ngcontent-sc306] {
        font-size: 5.8vw;
    }

    .stripe--2[_ngcontent-sc306] .stripe--status1[_ngcontent-sc306] a[_ngcontent-sc306] {
        font-size: 10vw;
    }

    .stripe--2[_ngcontent-sc306] .content--bottom[_ngcontent-sc306] {
        width: auto;
        display: block;
    }

    .stripe--status[_ngcontent-sc306] {
        padding-bottom: 60px;
    }

    .content--bottom--anim[_ngcontent-sc306] {
        flex-direction: column;
        width: 100%;
    }

    .stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
        font-size: 16px;
    }

    .arrow--menu[_ngcontent-sc306] {
        fill: none;
        stroke: #e6e6e6;
        stroke-miterlimit: 10;
    }
}

@media (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .stripe__item[_ngcontent-sc306] p[_ngcontent-sc306] {
        -ms-writing-mode: bt-lr;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        text-transform: uppercase;
        font-size: 1.7vh;
    }

    .stripe—1[_ngcontent-sc306] {
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: space-between;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10003;
        cursor: pointer;
    }

    .stripe—1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:first-child {
        background-color: #fbfbfb;
        padding-top: 35px;
        height: 40vh;
    }

    .stripe—1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:nth-child(2),
    .stripe—1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:nth-child(3) {
        background-color: #e6e6e6;
        height: 20vh;
    }

    .stripe—1[_ngcontent-sc306] .stripe__item[_ngcontent-sc306]:nth-child(4) {
        background-color: #2b8ccc;
        height: 20vh;
    }

    .hamburger[_ngcontent-sc306] {
        display: flex;
        margin-bottom: 10px;
        justify-content: center;
        width: 100%;
        height: 10px;
    }
}

header[_ngcontent-sc307] .logo[_ngcontent-sc307] {
    width: 150px;
    position: fixed;
    top: 50px;
    z-index: 1;
    opacity: 0.3;
    left: 10vw;
    transform-origin: left top;
}

.logo[_ngcontent-sc307] .cls-1[_ngcontent-sc307] {
    fill: #e6e6e6;
}

.input-form[_ngcontent-sc307],
.logo[_ngcontent-sc307] {
    position: fixed;
    z-index: 999;
}

header[_ngcontent-sc307] .input-form[_ngcontent-sc307] {
    top: 39px;
    right: 5vw;
}

@media screen and (max-width: 1024px) {
    header[_ngcontent-sc307] .logo[_ngcontent-sc307] {
        left: 30px;
    }
}

@media screen and (max-width: 768px) {
    header[_ngcontent-sc307] .input-form[_ngcontent-sc307] {
        top: 25px;
        right: 10vw;
    }

    header[_ngcontent-sc307] .logo[_ngcontent-sc307] {
        top: 30px;
    }
}

@media screen and (max-width: 500px) {
    header[_ngcontent-sc307] .input-form[_ngcontent-sc307] {
        right: 10vw;
    }
}

.section--1[_ngcontent-sc314] {
    padding-top: 60px;
    padding-bottom: 100px;
    height: 100vh;
    min-height: 1000px;
    width: 100%;
    position: relative;
}

.line[_ngcontent-sc314] {
    width: 100%;
    height: 1px;
    margin: 40px 0;
}

.section--1[_ngcontent-sc314] .pannello[_ngcontent-sc314] {
    position: absolute;
    z-index: 10000;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%);
    width: 30%;
}

.section--1[_ngcontent-sc314] .pannello[_ngcontent-sc314] img[_ngcontent-sc314] {
    width: 100%;
}

.section__content[_ngcontent-sc314] {
    position: relative;
}

.section--2[_ngcontent-sc314],
.section--3[_ngcontent-sc314],
.section--5[_ngcontent-sc314] {
    padding-top: 30vh;
}

.section--2[_ngcontent-sc314] img[_ngcontent-sc314] {
    width: 100%;
}

.section--3[_ngcontent-sc314] .section--flex[_ngcontent-sc314] {
    align-items: stretch;
}

.section--3[_ngcontent-sc314] .section--flex[_ngcontent-sc314] .col--50[_ngcontent-sc314]:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.section--5[_ngcontent-sc314] {
    display: flex;
}

.section--5--left[_ngcontent-sc314] {
    align-items: center;
    display: flex;
    position: relative;
    overflow: hidden;
}

.section--5--left[_ngcontent-sc314] p[_ngcontent-sc314] {
    font-size: 23vw;
    line-height: 1;
    margin-left: 10vw;
    color: #000;
    position: relative;
    z-index: 9;
    transition: all 0.5s ease-in-out;
}

.section--5--left[_ngcontent-sc314] p.animTextColor[_ngcontent-sc314] {
    color: #000;
    opacity: 0.2;
}

.section--5--right[_ngcontent-sc314] {
    padding-right: 10vw;
}

.section--5--left[_ngcontent-sc314] img[_ngcontent-sc314] {
    position: absolute;
    top: 0;
    left: -10vw;
    width: 130%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    transform: scale(1.05);
    opacity: 0.3;
}

.section--5--right[_ngcontent-sc314] p[_ngcontent-sc314]:hover .f--32[_ngcontent-sc314] {
    color: #f27107;
}

.section--5--right[_ngcontent-sc314] p[_ngcontent-sc314] .f--32[_ngcontent-sc314] {
    transition: all 0.2s ease;
}

.section--5--right[_ngcontent-sc314] p[_ngcontent-sc314] {
    padding-bottom: 15px;
}

.grid[_ngcontent-sc314] {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.grid__item[_ngcontent-sc314] {
    position: relative;
    padding: 20px 2.5vw calc(40px + 10vw);
}

.arrow-line--big[_ngcontent-sc314] {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 10;
    stroke-width: 2.83px;
}

@media screen and (max-width: 1024px) {
    .section--5[_ngcontent-sc314] {
        width: 90vw;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 768px) {

    .section--2[_ngcontent-sc314],
    .section--3[_ngcontent-sc314],
    .section--5[_ngcontent-sc314] {
        padding-top: 15vh;
    }

    .section--1[_ngcontent-sc314] {
        height: auto;
        min-height: auto;
        width: 90%;
        padding-top: 150px;
        align-items: center;
    }

    .section--1[_ngcontent-sc314] .pannello[_ngcontent-sc314] {
        position: relative;
        left: auto;
        bottom: auto;
        transform: none;
        width: 50%;
    }

    .section--2[_ngcontent-sc314],
    .section--3[_ngcontent-sc314] {
        width: 90%;
    }

    .section--2[_ngcontent-sc314] {
        padding-top: 50px;
    }

    .section--2[_ngcontent-sc314] .absolute[_ngcontent-sc314] {
        position: relative;
    }

    .section--5[_ngcontent-sc314] {
        display: block;
    }

    .section--5--left[_ngcontent-sc314] {
        width: 90vw;
        height: auto;
        overflow: unset;
    }

    .section--5--left[_ngcontent-sc314] p[_ngcontent-sc314] {
        font-size: 55vw;
        margin-left: 0;
    }

    .section--5--left[_ngcontent-sc314] img[_ngcontent-sc314] {
        width: 110%;
    }

    .section--5[_ngcontent-sc314] .section__subtitle[_ngcontent-sc314] {
        left: auto;
        right: 10vw;
        white-space: nowrap;
    }

    .section--5--right[_ngcontent-sc314] {
        width: 90vw;
        padding-bottom: 0;
        padding-top: 50px;
        margin-top: 20px;
    }

    .section--5--right[_ngcontent-sc314] div[_ngcontent-sc314] {
        width: 70vw;
        display: block;
    }

    .dash[_ngcontent-sc314] {
        display: none;
    }

    .section--5--right[_ngcontent-sc314] p[_ngcontent-sc314] {
        font-size: 5vw;
        line-height: 1.8;
    }

    .section--5--right[_ngcontent-sc314] app-link[_ngcontent-sc314] {
        bottom: 50px;
    }
}

@media screen and (max-width: 500px) {
    .section--10--bottom[_ngcontent-sc314] img[_ngcontent-sc314] {
        width: 70%;
    }
}

@media (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .section—1—left[_ngcontent-sc314] .counter[_ngcontent-sc314] {
        display: flex;
        height: 16vw;
        overflow: hidden;
        padding-right: 30px;
        margin-left: -3.5vw;
        position: absolute;
        top: 20vw;
        width: 100vw;
    }

    .link__wrap[_ngcontent-sc314] {
        height: 35px;
    }

    .section__subtitle[_ngcontent-sc314] {
        -ms-writing-mode: bt-lr;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        font-weight: 800;
        font-size: 16px;
        position: absolute;
        top: 0;
        left: 0;
    }
}

p[_ngcontent-sc309] {
    font-size: 16px;
    color: #e6e6e6;
    font-family: Archivo Bold;
}

.link-line1[_ngcontent-sc309],
.link-line2[_ngcontent-sc309] {
    fill: none;
    stroke: #f27107;
    stroke-miterlimit: 10;
    stroke-width: 2.83px;
}

svg[_ngcontent-sc309] {
    width: 130px;
}

a.container[_ngcontent-sc309] {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media screen and (max-width: 1024px) {
    a.container[_ngcontent-sc309] {
        align-items: flex-end;
    }
}

.section--10--top[_ngcontent-sc310] {
    padding: 200px 10vw;
}

.section--10--bottom[_ngcontent-sc310] {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 50vh;
    overflow: hidden;
}

.section--10--bottom[_ngcontent-sc310] .grid__item[_ngcontent-sc310] {
    overflow: hidden;
    position: relative;
    padding: 0;
    display: flex;
    align-items: flex-end;
}

.section--10--bottom[_ngcontent-sc310] .bg__img[_ngcontent-sc310] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transform: scale(1);
    transition: all 0.5s ease-in-out;
    z-index: -1;
}

.section--10--bottom[_ngcontent-sc310] .grid__item[_ngcontent-sc310]:hover .bg__img[_ngcontent-sc310] {
    opacity: 0.5;
    transform: scale(1.1);
}

.section--10[_ngcontent-sc310] .grid__item[_ngcontent-sc310] h3[_ngcontent-sc310] {
    transition: all 0.3s ease;
}

.section--10[_ngcontent-sc310] .grid__item[_ngcontent-sc310]:hover h3[_ngcontent-sc310] {
    color: #fff;
}

.section--10[_ngcontent-sc310] .item__content[_ngcontent-sc310] {
    margin-left: 10vw;
    padding-bottom: 30px;
}

.section--10--bottom[_ngcontent-sc310] svg[_ngcontent-sc310] {
    width: 15vw;
    margin-left: 5vw;
    margin-top: -35px;
    position: relative;
    z-index: 9;
}

.arrow-line--big[_ngcontent-sc310] {
    fill: none;
    stroke: #f27107;
    stroke-miterlimit: 10;
    stroke-width: 2.83px;
}

@media screen and (max-width: 1024px) {
    .arrow-line--big[_ngcontent-sc310] {
        stroke-width: 6px;
    }

    .section--10--bottom[_ngcontent-sc310] svg[_ngcontent-sc310] {
        width: 80px;
        margin-top: 0;
    }

    .section--10--top[_ngcontent-sc310] {
        margin-left: 0;
        width: 90vw;
        padding: 150px 30px 50px;
    }

    .section--10--bottom[_ngcontent-sc310] {
        display: block;
        height: auto;
        width: 90vw;
        padding-left: 30px;
        padding-right: 30px;
    }

    .section--10[_ngcontent-sc310] .grid__item[_ngcontent-sc310] h3[_ngcontent-sc310] {
        font-size: 22px;
        text-align: left;
    }

    .section--10[_ngcontent-sc310] .grid__item[_ngcontent-sc310] p[_ngcontent-sc310] {
        text-align: right;
        font-size: 16px;
        font-family: Archivo Bold;
    }

    .section--10[_ngcontent-sc310] .item__content[_ngcontent-sc310] {
        margin-left: 0;
        align-items: flex-end;
        margin-right: 0;
        width: 100%;
        padding-bottom: 100px;
        padding-top: 30px;
    }

    .section--10[_ngcontent-sc310] .item__content[_ngcontent-sc310] div[_ngcontent-sc310] {
        width: 100%;
    }

    .section--10--bottom[_ngcontent-sc310] img[_ngcontent-sc310] {
        width: 50%;
    }

    .section--10--bottom[_ngcontent-sc310] a[_ngcontent-sc310] {
        width: 100%;
    }
}

@media (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .section--10--bottom[_ngcontent-sc310] {
        display: -ms-flexbox;
        justify-content: space-between;
        height: 50vh;
        overflow: hidden;
    }

    .section--10--bottom[_ngcontent-sc310] .grid__item[_ngcontent-sc310] {
        overflow: hidden;
        position: relative;
        padding: 0;
        display: flex;
        align-items: flex-end;
        width: 50%;
    }
}

.section--11[_ngcontent-sc311] {
    justify-content: space-between;
    padding-top: 200px;
    padding-bottom: 200px;
}

@media screen and (max-width: 1024px) {
    .section--11[_ngcontent-sc311] {
        padding-top: 100px;
        padding-bottom: 100px;
        width: 90vw;
    }
}

footer[_ngcontent-sc312] {
    background-color: #191919;
    background-image: url(https://ik.imagekit.io/7pj6eu6xf/footer-map2.dd30896ea919aca1837c.png?updatedAt=1682314726393);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 0 10vw;
}

h4[_ngcontent-sc312] {
    font-size: 20px;
}

footer[_ngcontent-sc312] p[_ngcontent-sc312] {
    font-size: 14px;
}

a[_ngcontent-sc312] {
    color: #fff;
}

.grid[_ngcontent-sc312] {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 30vw;
}

.content[_ngcontent-sc312] {
    padding: 50px 0 20px;
}

.content[_ngcontent-sc312] a[_ngcontent-sc312] {
    font-family: Archivo Bold;
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
}

.line[_ngcontent-sc312] {
    height: 1px;
    width: 100%;
    background-color: #f27107;
    margin-top: 30px;
    margin-bottom: 30px;
}

.subfooter[_ngcontent-sc312] {
    display: flex;
    justify-content: space-between;
    padding: 0 0 50px;
}

.subfooter[_ngcontent-sc312] a[_ngcontent-sc312],
.subfooter[_ngcontent-sc312] p[_ngcontent-sc312] {
    font-size: 12px;
}

@media screen and (max-width: 1024px) {
    footer[_ngcontent-sc312] {
        padding: 0 30px;
        width: 100vw;
        background-position: 50%;
        background-size: cover;
    }

    .grid[_ngcontent-sc312] {
        width: 100%;
    }

    .content[_ngcontent-sc312] {
        padding-top: 0;
    }
}

@media screen and (max-width: 768px) {
    .subfooter[_ngcontent-sc312] {
        display: block;
        padding: 20px 0 80px;
    }

    .line[_ngcontent-sc312],
    .subfooter[_ngcontent-sc312] p[_ngcontent-sc312] {
        margin-bottom: 10px;
    }
}

@media (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .grid[_ngcontent-sc312] {
        display: -ms-flexbox;
        justify-content: space-between;
    }
}

.loader[_ngcontent-sc313] {
    width: 100vw;
    height: 100vh;
    background-color: #191919;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 100000;
    padding: 10vw;
}

.loading-counter__container[_ngcontent-sc313] {
    width: 100%;
    text-align: right;
}

span[_ngcontent-sc313] {
    display: block;
}

.loading-line[_ngcontent-sc313] {
    padding: 30px 0;
}

.loading-line[_ngcontent-sc313] .cls-1[_ngcontent-sc313],
.loading-line[_ngcontent-sc313] .cls-2[_ngcontent-sc313] {
    fill: none;
    stroke-miterlimit: 10;
}

.loading-line[_ngcontent-sc313] .cls-1[_ngcontent-sc313] {
    stroke: #282828;
    stroke-width: 10px;
}

.loading-line[_ngcontent-sc313] .cls-2[_ngcontent-sc313] {
    stroke: #f27107;
    stroke-width: 10px;
}

@media screen and (max-width: 1024px) {
    .loading-line[_ngcontent-sc313] svg[_ngcontent-sc313] {
        height: 1px;
        width: 100%;
    }

    p[_ngcontent-sc313] {
        margin-right: 2vw;
    }

    .loader[_ngcontent-sc313] {
        padding: 20vh 30px;
    }
}